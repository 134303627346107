<template>
  <b-overlay :show="fetching" rounded="sm">
    <div class="page">
      <div class="page__title">
        {{ getModuleConfig("staff_specific_page.form_title") }}
      </div>
      <div class="page__desc text-center">
        {{ getModuleConfig("staff_specific_page.form_desc") }}
      </div>
      <div class="field" v-if="isCanBinding">
        <div class="sales__wrapper">
          <swiper v-if="staffs" :options="swiperOption" class="sales align-items-stretch" >
            <swiper-slide  v-for="(i, idx) in staffs" :key="idx" class="sales__item">
              <div class="card text-center d-flex flex-column">
                <div v-if="showAvatar">
                  <img
                    v-if="displayAvatar(i)"
                    :src="displayAvatar(i)"
                    class="card-img-top"
                  />
                  <img
                    v-else
                    src="./default_staff_img.jpg"
                    class="card-img-top"
                  />
                </div>

                <div class="p-3 flex-fill d-flex flex-column">
                  <div class="flex-fill">
                  <table v-if="staffFields.length != 0" class="mx-auto my-3 align-text-top" >
                    <tr v-for="(field, index) in staffFields" :key="index">
                      <template v-if="field.title">
                        <td class="font-weight-bold text-nowrap align-text-top">{{ field.title }}</td>
                        <td class="text-left align-text-top">{{ displayField(i, field) }}</td>
                      </template>
                      <template v-else>
                        <td colspan="2" class="align-text-top">{{ displayField(i, field) }}</td>
                      </template>
                    </tr>
                  </table>
                  <template v-else>
                    <div>{{ i.name }}</div>
                  </template>
                </div>
              </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div v-else>
        <div
          class="page__content"
          v-if="getModuleConfig('staff_specific_page.multiple_bind.forbid_text')"
          v-html="getModuleConfig('staff_specific_page.multiple_bind.forbid_text')"
        ></div>
      </div>
    </div>

    <div class="page__button s-space-y-4">
      <MemberButton
        v-if="isCanBinding"
        class="s-btn-bg-primary mb-2"
        :disable-button="!selectedStaff.id"
        @custom-click="handleSubmit"
        :button-text="displayButtonText"
        :button-url="getModuleConfig('staff_specific_page.bind_button.button_url')"
        :type="getModuleConfig('staff_specific_page.bind_button.button_action')"
        :use-callback="true"
      >
      </MemberButton>
      <MemberButton
        :button-style="`s-btn-outline-primary`"
        v-if="getModuleConfig('staff_specific_page.button_text') && isCanBinding"
        :button-text="getModuleConfig('staff_specific_page.button_text')"
        :button-url="getModuleConfig('staff_specific_page.button_url')"
        :type="getModuleConfig('staff_specific_page.button_action')"
      >
      </MemberButton>
      <!-- 不能多重綁定的按鈕 -->
      <MemberButton
        :button-style="`s-btn-outline-primary`"
        v-if="getModuleConfig('staff_specific_page.multiple_bind.button_text') && !isCanBinding"
        :button-text="getModuleConfig('staff_specific_page.multiple_bind.button_text')"
        :button-url="getModuleConfig('staff_specific_page.multiple_bind.button_url')"
        :type="getModuleConfig('staff_specific_page.multiple_bind.button_action')"
      >
      </MemberButton>
    </div>
  </b-overlay>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import { mapGetters } from "vuex";
import liff from "@line/liff";
import staffApi from "@/apis/liff/v2/staff";
import mixinStaff from "@/mixins/liff/staff";
import LiffChecker from "@/utils/LiffChecker";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  mixins: [mixinStaff],
  computed: {
    ...mapGetters("liffGeneral", ["liffId"]),
    ...mapGetters({
      getModule: "liffModule/getModule",
    }),
    showAvatar() {
      return (
        this.getModuleConfig("staff_specific_page.staffs_list.show_avatar") ??
        true
      ) && !this.fetching;
    },
    staffFields() {
      return (
        this.getModuleConfig("staff_specific_page.staffs_list.fields") ?? []
      ).sort((a, b) => a.order - b.order);
    },
    displayButtonText() {
      const text = this.getModuleConfig(
        "staff_specific_page.bind_button.button_text"
      );
      if (text) {
        return this.templateReplace(text, { staff: this.selectedStaff });
      } else {
        return this.getModuleConfig(
          "staff_specific_page.bind_button.button_default_text"
        );
      }
    },
    displaySendLineMessage() {
      const text = this.getModuleConfig("staff_specific_page.bind_button.send_messages.text")
      return this.templateReplace(text, { staff: this.selectedStaff })
    },
    showBranchCode() {
      return (
        this.getModuleConfig("staff_specific_page.show_branch_code") ?? true
      );
    },
    staffId() {
      return this.$route.params.staffId;
    },
    swiperOption() {
      // todo single one how to show
      return {
        //loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        wrapperClass:'swiper-wrapper',
        //width: 220, // 不知為啥 他是 px*2數字 然後會扣掉spaceBetween(px) 才會是他Card Width
        spaceBetween: 10,
        breakpoints: {
          768: {
            //width: undefined,
            spaceBetween: 30,
          },
        },
      }
    }
  },
  components: {
    MemberButton,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      fetching: true,
      selectedStaff: {
        id: null,
      },
      staffs: [],
      lineData: {},
      fetchingStaff: false,
      isCanBinding: true
    };
  },
  watch: {},

  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.checkBinding();
      if (!this.isCanBinding) {
        this.fetching = false;
        return
      }
      await liff
        .init({
          liffId: this.liffId,
        })
        .then(() => {
          if (!liff.isLoggedIn()) {
            liff.login({
              redirectUri: window.location.href,
            });
          }
        })
        .catch(() => {
          alert("初始化頁面失敗，請確認此網址是否正確");
        });
      const checker = new LiffChecker(this.$route.params.orgCode);
      this.lineData = await checker.getLineData();
      this.fetchStaff();
      this.fetching = false;
    },
    async checkBinding() {
      await staffApi.checkBinding().then((res) => {
        // 如果綁定 且可以多重綁定
        if (res.isBound) {
          if (this.getModuleConfig("staff_specific_page.multiple_bind.enable")) {
            this.isCanBinding = true
          } else {
            this.isCanBinding = false
          }
        } else {
          this.isCanBinding = true
        }
      });
    },
    async fetchStaff() {
      this.fetching = true;
      await staffApi.getStaff(this.staffId).then((res) => {
        this.staffs = [res]
        this.selectedStaff = res
        this.fetching = false;
      });
    },
    async handleSubmit(callback) {
      try {
        this.fetching = true;
        await this.doSubmit();
        callback(null);
      } catch (error) {
        this.fetching = false;
        callback(error);
      }
    },
    doSubmit() {
      return new Promise((resolve, reject) => {
        if (this.getModuleConfig('staff_specific_page.bind_button.send_messages.enable')) {
          this.sendLineMessage()
        }
        staffApi
          .customerBindStaff({
            line_id: this.lineData.profile.userId,
            line_access_token: this.lineData.accessToken,
            sid: this.selectedStaff.id,
            tags: this.tags,
            from_page: 'staff_specific_page',
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    displayAvatar(staff) {
      if (this.fetching) {
        return '';
      }
      if (staff.avatar_url) {
        return staff.avatar_url;
      } else {
        return this.getModuleConfig(
          "staff_specific_page.staffs_list.default_avatar_url"
        );
      }
    },
    displayField(staff, field) {
      if (field.type == "date") {
        return this.$moment(staff[field.matched_key]).format("YYYY-MM-DD");
      }
      return staff[field.matched_key] ?? "";
    },
    selectStaff(staff) {
      this.selectedStaff = staff;
    },
    sendLineMessage() {
      liff
        .sendMessages([
          {
            type: "text",
            text: this.displaySendLineMessage,
          },
        ])
        .then(() => {
          console.log("message sent");
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
.sales {
  margin: 5px -16px;
  padding: 16px 0;

  &__wrapper {
    //min-height: 460px;
  }

  &__button {
    opacity: 0.5;
    &__active {
      opacity: 1;
    }
  }

  &__item {
    width: 220px;
    height: revert;

    .card {
      border-radius: 0.5rem;
      height: 100%;

      .card-img-top {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
    }
  }

  ::v-deep.swiper-wrapper {
    align-items: stretch;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: gray;
    background: white;
    border-radius: 100%;
    aspect-ratio: 1;
    width: auto;
    &::after {
      font-size: 1.5rem;
    }
  }
}

.page {
  padding: 5px 5px;
}

::v-deep .spinner-border {
  border-width: 0.2em !important;
}
</style>
